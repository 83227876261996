import React, { useEffect, useState } from "react";
import { SingleModule } from "@master-wizr/mw-trial";
import "@master-wizr/mw-trial/dist/index.css";
import NoSSR from "react-no-ssr";
import Header from "../components/atoms/header";
import MainSlider from "../components/molecules/MainSlider/MainSlider";
import Fullscreen from "../images/fullscreen.svg";
import ExitFullscreen from "../images/exitfullscreen.svg";
import { Helmet } from "react-helmet";
export default function WiZRConnect() {
  const [loadAnimation, setLoadAnimation] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    setLoadAnimation("fadeInOnLoad");
  }, []);
  const data = [
    {
      image:
        "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704217785/Rectangle_15357_mi1i8c.png",
    },
    {
      image:
        "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704217775/Rectangle_15343_tytvhq.png",
    },
    {
      image:
        "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704217780/Rectangle_15360_yreune.png",
    },
    {
      image:
        "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704217775/Group_1321314326_y9kkzk.png",
    },
    {
      image:
        "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704217777/Rectangle_15365_uswwge.png",
    },
  ];
  return (
    <>
      <Helmet>
        <title>WiZR Connect</title>
        <link rel="canonical" href="https://masterwizr.com/wizr-connect" />
      </Helmet>
      <div className={`main-div ${loadAnimation}`}>
        <button className="fullscreenBtn" onClick={toggleFullscreen}>
          {isFullscreen ? (
            <>
              <ExitFullscreen />
              <div className="fullscreenTooltip">
                <h3 className="tooltip">Exit Full Screen</h3>
              </div>
            </>
          ) : (
            <>
              <Fullscreen />
              <div className="fullscreenTooltip">
                <h3 className="tooltip">Go Full Screen</h3>
              </div>
            </>
          )}
        </button>
        <Header />
        <NoSSR>
          <SingleModule location={"/trial-steps"} module="Connect" />
        </NoSSR>
        <MainSlider
          height="100%"
          slidesNumber={1}
          title="Product Snapshots"
          data={data}
          slideWidth="85%"
        />
      </div>
    </>
  );
}
